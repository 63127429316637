/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import {SingleSelectionFilter} from './FilterComponents/SingleSelectionFilter/SingleSelectionFilter';
import {MultipleSelectionFilter} from './FilterComponents/MultipleSelectionFilter/MultipleSelectionFilter';
import {ColorFilter} from './FilterComponents/ColorFilter/ColorFilter';
import type {FilterModel, IFilterModel} from '../../../types/galleryTypes';
import {FilterType, MobileFiltersPanelState} from '../../../types/galleryTypes';
import {RangeFilter} from './FilterComponents/RangeFilter/RangeFilter';
import {FiltersHeader} from './FiltersHeader/FiltersHeader';
import {PriceFilterModel} from '../../../models/PriceFilterModel';
import s from './Filters.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {FiltersFooter} from './FiltersFooter/FiltersFooter';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {CategoryTree} from '../../../category/components/CategoryTree/CategoryTree';
import {useStyles} from '@wix/tpa-settings/react';
import {ExpandableOption} from '../ExpandableOption/ExpandableOption';
import {RadioButton, RadioButtonGroup} from 'wix-ui-tpa/cssVars';
import {useStylesParams} from '../../../stylesParamsContext';
import classNames from 'classnames';
import {classes as stylableClasses} from './Filters.st.css';
import {hasRelevantFilters} from '../GalleryApp/appUtils';
import {hasActiveOptions} from './filterUtils';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {CategoryListLayout, Experiments, GallerySlotIds} from '../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {ConditionalRender} from '../../../common/components/ConditionalRender/ConditionalRender';

export interface FiltersProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  clearFilters: Function;
  shouldShowClearFiltersButton: boolean;
  modalHeaderId?: string;
  combineFiltersAndSort?: boolean;
}

export const Filters: React.FunctionComponent<FiltersProps & IGalleryGlobalProps & IProvidedTranslationProps> =
  withGlobals(
    withTranslations()(
      ({
        shouldShowClearFiltersButton,
        modalHeaderId,
        combineFiltersAndSort,
        clearFilters,
        t,
        globals: {
          experiments: {fixGalleryRenderingWhenUrlChanges, shouldRenderSlotsInGallery},
          filterModels,
          shouldShowMobile,
          isCategoryPage,
          usingExtendedGridGalleryStyles,
          mobileFiltersPanelState,
          shouldShowSort,
          selectedSort,
          sortingOptions,
          setSelectedSort,
          sortProducts,
          totalProducts,
          hasSelectedFilters,
          categories,
          slots,
        },
      }: FiltersProps & IGalleryGlobalProps & IProvidedTranslationProps) => {
        const styles = useStyles();
        const stylesParams = useStylesParams();

        const getMobileSortingComponent = () => {
          const sortingOptionsComp = (
            <RadioButtonGroup
              className={stylableClasses.radioButtonGroupRoot}
              data-hook="sort-in-mobile-panel"
              value={selectedSort.id}
              name="sort-by"
              onChange={(optionId) => {
                const sortFn = fixGalleryRenderingWhenUrlChanges ? sortProducts : setSelectedSort;
                sortFn(sortingOptions.find((option) => option.id === optionId));
              }}>
              {sortingOptions.map((option) => (
                <RadioButton
                  className={stylableClasses.radioButtonRoot}
                  label={t(option.titleKey)}
                  value={option.id}
                  key={option.id}
                />
              ))}
            </RadioButtonGroup>
          );

          return mobileFiltersPanelState === MobileFiltersPanelState.SORT_ONLY ? (
            <li key="sort" data-hook={`filter-type-sort`}>
              {sortingOptionsComp}
            </li>
          ) : (
            <li key="sort" data-hook={`filter-type-sort`}>
              <ExpandableOption title={t('sortByText')} expanded={true}>
                {sortingOptionsComp}
              </ExpandableOption>
            </li>
          );
        };

        const getFilterComponent = (filterModel: FilterModel) => {
          switch (filterModel.filterType) {
            case FilterType.COLLECTION:
              return usingExtendedGridGalleryStyles ? (
                <MultipleSelectionFilter
                  filterId={filterModel.filterId}
                  selected={[...filterModel.activeOptions]}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              ) : (
                <SingleSelectionFilter
                  selected={filterModel.activeOptions}
                  filterId={filterModel.filterId}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              );
            case FilterType.CUSTOM_COLLECTION:
              return (
                <MultipleSelectionFilter
                  filterId={filterModel.filterId}
                  selected={[...filterModel.activeOptions]}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              );
            case FilterType.COLOR_OPTION:
              return (
                <ColorFilter
                  selected={[...filterModel.activeOptions]}
                  filterId={filterModel.filterId}
                  colorOptions={filterModel.options.map((option) => ({key: option.key, value: option.value}))}
                />
              );
            case FilterType.LIST_OPTION:
              return (
                <MultipleSelectionFilter
                  selected={[...filterModel.activeOptions, ...filterModel.selectedOptions]}
                  filterId={filterModel.filterId}
                  options={filterModel.options.map((option) => ({
                    id: option.key,
                    name: option.value,
                  }))}
                />
              );
            case FilterType.PRICE:
              const selectedRange = {
                min: filterModel.activeOptions.minPrice,
                max: filterModel.activeOptions.maxPrice,
              };
              return (
                <RangeFilter
                  filterId={filterModel.filterId}
                  options={filterModel.options}
                  selectedRange={selectedRange}
                  key={`${selectedRange.min}-${selectedRange.max}`}
                />
              );
          }
        };

        const isExpanded = (index: number, shouldRenderSort: boolean, filterModel: IFilterModel) => {
          return (!shouldRenderSort && index === 0) || hasActiveOptions(filterModel);
        };

        const getFilterListView = (currentFilterModels: FilterModel[]) => {
          const shouldRenderSort = combineFiltersAndSort && shouldShowSort;
          const shouldRenderFilter = combineFiltersAndSort
            ? mobileFiltersPanelState === MobileFiltersPanelState.FILTERS_AND_SORT ||
              mobileFiltersPanelState === MobileFiltersPanelState.FILTERS_ONLY
            : hasRelevantFilters(filterModels);

          return (
            <ul
              data-hook={`filters-list`}
              className={classNames(s.filterList, {
                [s.categoryPage]: isCategoryPage,
                [s.usingExtendedGridGalleryStyles]: usingExtendedGridGalleryStyles,
              })}>
              {shouldRenderSort && getMobileSortingComponent()}
              {shouldRenderFilter &&
                currentFilterModels.map((filterModel: FilterModel, index) => (
                  <li key={filterModel.filterId} data-hook={`filter-type-${filterModel.filterType}`}>
                    <ExpandableOption
                      title={filterTitle(filterModel)}
                      filterType={filterModel.filterType}
                      expanded={isExpanded(index, shouldRenderSort, filterModel)}>
                      {getFilterComponent(filterModel)}
                    </ExpandableOption>
                  </li>
                ))}
            </ul>
          );
        };
        const renderFilters = () => {
          return (
            <>
              <FiltersHeader
                modalHeaderId={modalHeaderId}
                combineFiltersAndSort={combineFiltersAndSort}
                data-hook={'side-filter-header'}
              />
              {getFilterListView(filterModels)}
              <FiltersFooter
                clearFilters={clearFilters}
                shouldShowClearFiltersButton={shouldShowClearFiltersButton}
                filtersPanelState={mobileFiltersPanelState}
              />
            </>
          );
        };

        const filterTitle = (filterModel: FilterModel) => {
          let title = filterModel.title;

          if (!shouldShowMobile) {
            return title;
          }

          if (Array.isArray(filterModel.activeOptions)) {
            const length = filterModel.activeOptions.length;
            if (length > 0) {
              title += ` (${length})`;
            }
          }

          if (filterModel.activeOptions && (filterModel as PriceFilterModel).activeOptions.minPrice) {
            const filterModelAsPrice = filterModel as PriceFilterModel;
            const minPriceFormatted = filterModelAsPrice.options.find(
              (v) => v.key === filterModelAsPrice.activeOptions.minPrice
            ).value;
            const maxPriceFormatted = filterModelAsPrice.options.find(
              (v) => v.key === filterModelAsPrice.activeOptions.maxPrice
            ).value;
            title += ` (${minPriceFormatted} - ${maxPriceFormatted})`;
          }

          return title;
        };

        const {experiments} = useExperiments();

        const showCategoryList = styles.get(stylesParams.gallery_showCategories);
        const enableNewCategoryList = experiments.enabled(Experiments.EnableStorefrontSubCategories);
        const allowGalleryContainerPadding = experiments.enabled(Experiments.AllowGalleryContainerPadding);
        const verticalCategoryList = enableNewCategoryList
          ? styles.get(stylesParams.gallery_categoryListLayout) === CategoryListLayout.Vertical && !shouldShowMobile
          : true;

        const shouldRenderCategoryTree = enableNewCategoryList
          ? isCategoryPage && verticalCategoryList && !!categories.length
          : isCategoryPage && !shouldShowMobile && showCategoryList && verticalCategoryList && !!categories.length;

        const shouldRenderFilters = () => {
          if (isCategoryPage) {
            return (hasRelevantFilters(filterModels) && (hasSelectedFilters || totalProducts > 0)) || shouldShowMobile;
          } else {
            return true;
          }
        };

        const renderCategoryTree = () => {
          if (!enableNewCategoryList) {
            return <CategoryTree />;
          }

          return (
            <ConditionalRender by={'gallery_showCategories'}>
              <CategoryTree />
            </ConditionalRender>
          );
        };

        const isPluginInstalledInCategoryPageListTopSlot = !!slots?.[GallerySlotIds.CategoryPageListTop];
        const isPluginInstalledInGalleryFiltersTopSlot = !!slots?.[GallerySlotIds.GalleryFiltersTop];
        const isPluginInstalledInGalleryFiltersBottomSlot = !!slots?.[GallerySlotIds.GalleryFiltersBottom];

        return (
          <section
            className={classNames(s.filters, {
              [s.usingExtendedGridGalleryStyles]: usingExtendedGridGalleryStyles,
              [s.newCategoryListEnabled]: enableNewCategoryList,
              [s.fixedPadding]: !allowGalleryContainerPadding,
              [s.dynamicPadding]: allowGalleryContainerPadding,
            })}>
            {!enableNewCategoryList && shouldRenderSlotsInGallery && shouldRenderCategoryTree && (
              <div className={classNames({[s.categoryPageListTopSlot]: isPluginInstalledInCategoryPageListTopSlot})}>
                <SlotsPlaceholder slotId={GallerySlotIds.CategoryPageListTop} />
              </div>
            )}
            {shouldRenderCategoryTree && renderCategoryTree()}
            {shouldRenderSlotsInGallery && (
              <div className={classNames({[s.galleryFiltersTopSlot]: isPluginInstalledInGalleryFiltersTopSlot})}>
                <SlotsPlaceholder slotId={GallerySlotIds.GalleryFiltersTop} />
              </div>
            )}
            {shouldRenderFilters() ? renderFilters() : undefined}
            {shouldRenderSlotsInGallery && (
              <div className={classNames({[s.galleryFiltersBottomSlot]: isPluginInstalledInGalleryFiltersBottomSlot})}>
                <SlotsPlaceholder slotId={GallerySlotIds.GalleryFiltersBottom} />
              </div>
            )}
          </section>
        );
      }
    )
  );
